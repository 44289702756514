
import { AuthType } from "@/enums/AuthType";
import { computed, ComputedRef } from "vue";
import LoyaltyPointsPanel from "./LoyaltyPointsPanel.vue";

export default {
  name: "PaymentInfoPanel",
  components: {
    LoyaltyPointsPanel
  },
  props: {
    sumAmount: Number,
    authType: Number,
    isLoyaltyPointsLoading: Boolean
  },
  setup(props: any) {
    const sumAmountAsString = (): String => {
      let sum = props.sumAmount;

      if (props.authType === AuthType.verify) {
        sum = 0;
      }

      return sum.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    };

    const integerSumPart: ComputedRef<String> = computed(
      () => sumAmountAsString().split(".")[0]
    );

    const decimalSumPart: ComputedRef<String> = computed(
      () => sumAmountAsString().split(".")[1]
    );

    return {
      integerSumPart,
      decimalSumPart
    };
  },
};
